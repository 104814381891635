@use "../../base.scss"; 

.introduction-container {
background-color: white !important;
}

.heading {
    color: base.$color-two;
    font-size: 4.25rem;
    letter-spacing: .08em;
    line-height: 1.1;
    font-family: "Avenir Next", Sans-serif;
    font-weight: 900;
    text-transform: uppercase;
    position: relative;
}

.introduction-hr {
    border: 6px solid #0b9aab;
    fill: #0b9aab;
    background-color: #0b9aab;
    width: 34%;
    position: absolute;
    bottom: -25px;
}

.subheading {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1.25rem;
    line-height: 1.3;
    letter-spacing: 0.04rem;
    color: black;
}


.text {
    font-size: 1.125rem;
    line-height: 1.5;
    color: black;
    font-weight: 600;
}

@media screen and (max-width: 1024px) {
    .heading {
        font-size: 3.25rem;
    }
    
}

@media screen and (max-width: 768px) {
    .heading {
        font-size: 2.25rem;
    }
    
}

@media screen and (max-width: 426px) {
    .heading {
        font-size: 1rem;
    }

    .introduction-hr {
        border: 3px solid #0b9aab;
    }

    .subheading {
        font-size: 0.75rem;
    }

    .text {
        font-size: 0.8rem;
        margin-top: 0px!important;
        text-align: justify;
    }
}


@media screen and (max-width: 321px) {
    .heading {
        font-size: 1rem;
    }
}





