/* Start Custom Fonts CSS */@font-face {
	font-family: 'Avenir Next';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url('./fonts/avenirnext-bold.woff2') format('woff2'),
}
@font-face {
	font-family: 'Avenir Next';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url('./fonts/avenirnext-regular.woff2') format('woff2'),
}
@font-face {
	font-family: 'Avenir Next';
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: url('./fonts/avenirnext-heavy.woff2') format('woff2'),
}
@font-face {
	font-family: 'Avenir Next';
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url('./fonts/avenirnext-medium.woff2') format('woff2'),
		}
@font-face {
	font-family: 'Avenir Next';
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src:url('./fonts/avenirnext-demibold.woff2') format('woff2'),
}
/* End Custom Fonts CSS */


* {
	font-family: "Avenir Next", Sans-serif;
}