@use "../../base.scss";

.footer-container {
    background-color: base.$color-three;
    color: white;
    min-height: 200px;
}

.tag-line {
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1.3;
    font-size: 1.5rem;
    letter-spacing: .04em;
}


.copyright {
    font-size: 1rem;
    letter-spacing: .04em;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
}


@media screen and (max-width: 768px) {
    .tag-line {
        font-size: 1.25rem;
    }    
}

@media screen and (max-width: 426px) {
    .tag-line {
        font-size: 0.7rem;
    }

    .copyright {
        font-size: 0.7rem;
    }
}


@media screen and (max-width: 321px) {
    .tag-line, .copyright {
        font-size: 0.55rem;
    }
}





