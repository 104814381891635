@use "../../base.scss";

.header-container {
    background-color: base.$color-one;
}

.greetings {
    font-family: "Avenir Next", Sans-serif;
    font-weight: 900;
    text-transform: uppercase;
    line-height: 1.1em;
    font-size: 10.25rem;
    letter-spacing: .08em;
    color: base.$color-two;
    text-align: center;
    margin-top: 100px;
    word-wrap: break-word;
    text-align: start;

}


.subheading {
    font-size: 1.5rem;
    letter-spacing: .04em;
    color: white;
    font-weight: bold;
    padding-top: 10px;
    padding-bottom: 30px;
    font-family: "Avenir Next", Sans-serif;

}

.brand-container {
    font-weight: bold;
    font-family: "Avenir Next", Sans-serif;
    letter-spacing: 0.01rem;
    text-transform: uppercase;
    font-size: 2.5rem;
    padding-bottom: 30px;

}

@media screen and (max-width: 1024px) {
    .greetings {
        font-size: 8.25rem;
    }
    
}

@media screen and (max-width: 768px) {
    .greetings {
        font-size: 6.25rem;
    }
    
}

@media screen and (max-width: 426px) {
    .greetings {
        font-size: 2.25rem;
        margin-top: 55px;
    }

    .subheading, .brand-container {
        font-size: 1rem;
    }

    
}


@media screen and (max-width: 321px) {
    .greetings {
        font-size: 1.25rem;
    }

    .subheading {
        font-size: 1rem;
    }
    
}





